import React, { useEffect } from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import anime from 'animejs';
import styles from './IconTerracedHouse.module.scss';

const IconTerracedHouse = ({ shouldStart = false, id = '' }) => {
    const HouseAnimate = () => {
        anime({
            targets: `.${id}terracedLine`,
            translateX: 1,
            easing: 'easeInOutSine',
            duration: 300,
            direction: 'alternate',
            loop: false,
        });
    };

    const ReverseHouseAnimate = () => {
        anime({
            targets: `.${id}terracedLine`,
            translateX: -5,
            easing: 'easeInOutSine',
            duration: 300,
            direction: 'alternate',
            loop: false,
        });
    };

    useEffect(() => {
        shouldStart ? HouseAnimate() : ReverseHouseAnimate();
    });

    return (
        <div className={styles['IconTerracedHouse']}>
            <svg
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    className={id + 'terracedLine'}
                    d="M34.613 42.029a1 1 0 1 0 0 2v-2zM27.487 10.22a1 1 0 1 0 1.253 1.558l-1.253-1.558zm1.253 1.558 3.765-3.026-1.254-1.56-3.764 3.028 1.253 1.558zm4.991-3.047L46.1 18.009l1.2-1.6-12.37-9.277-1.2 1.6zM46.5 18.809v22.22h2v-22.22h-2zm-1 23.22H34.613v2H45.5v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm-.4-23.02a1 1 0 0 1 .4.8h2a3 3 0 0 0-1.2-2.4l-1.2 1.6zM32.504 8.753a1 1 0 0 1 1.227-.021l1.2-1.6a3 3 0 0 0-3.68.062l1.253 1.559z"
                    fill="#feca01"
                />
                <path
                    d="m18.49 7.891-.6.8.6-.8zM3.614 41.03V18.82h-2v22.21h2zm.408-23.014 12.677-9.329-1.186-1.61-12.677 9.328 1.186 1.61zM17.89 8.69l12.423 9.318 1.2-1.6L19.09 7.09l-1.2 1.6zm12.824 10.12v22.22h2V18.81h-2zm-1 23.22h-25.1v2h25.1v-2zm1-1a1 1 0 0 1-1 1v2a3 3 0 0 0 3-3h-2zm-.4-23.02a1 1 0 0 1 .4.8h2a3 3 0 0 0-1.2-2.4l-1.2 1.6zM16.698 8.686a1 1 0 0 1 1.193.005l1.2-1.6a3 3 0 0 0-3.579-.016l1.186 1.611zM3.613 18.82a1 1 0 0 1 .408-.805l-1.186-1.611a3 3 0 0 0-1.222 2.416h2zm-2 22.209a3 3 0 0 0 3 3v-2a1 1 0 0 1-1-1h-2z"
                    fill="currentColor"
                />
            </svg>
        </div>
    );
};

IconTerracedHouse.propTypes = {};

export default IconTerracedHouse;
